/* @import url('https://fonts.googleapis.com/css?family=Dosis&font-display=swap'); */
* {
    box-sizing: border-box;
    margin: 0;
}
#root{
    min-width: 100vw;
    min-height: 100vh;
}

.container {
    width: 87vw;
    margin: auto;
}
.flex-around{
    display:flex;
    justify-content: space-around
}

body {
    color:#fff;
    /* font-family: 'Dosis', Helvetica, sans-serif; */
    font-family: Helvetica, sans-serif;
    background-color: transparent;
    position: relative;
    z-index: 1;
}

body a {
    color: #fff;
    text-decoration: none;
}
p {
    margin-bottom: .25cm;
}

#particles{
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: -1;
    height: 100vh;
    width:100vw;
    background: linear-gradient(to right, rgb(62, 68, 92), rgb(81, 87, 112));
}


nav, footer {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.2cm;
    color: #fff;
}
footer {
    margin-top:0.5cm;
}

header {
    margin:0.5cm 0 1cm 0;
}
aside {margin-top:0.5cm}

header h1 {
    letter-spacing: 0cm;
}
.halfs {
    display: grid;
    grid-template-columns: 1fr;
    margin-top:0.5cm;
}
ul {
    list-style: square;
}
li{
    margin-top:10px;
    margin-bottom:25px;
    margin-left:-20px;
}
.skills{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}
.icon {margin:0.5cm}
.portImg{
    width: 100%;
    border: thick white solid;
    border-radius: 5px;
}
.aboutBold {
    display: inline-block;
    font-weight: 800;
    min-width: 2cm;
}
.aboutBold:after {
    /* position: absolute; */
    content: ' : ';
    font-size: 14px;
    left: 77px;
}
.aboutData {
    display: inline;
    padding-right: 2cm;
}
.hidden {
    visibility: hidden;
    overflow: hidden;
}


@media(min-width:576px) {
    header h1 {
        letter-spacing: 0.1cm;
    }   
}

@media(min-width:768px) {
    header h1 {
        letter-spacing: 0.2cm;
    }
    .halfs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5cm
    }   
}

@media(min-width:922px) {
    header h1 {
        letter-spacing: 0.3cm;
    }
    .grid23{
        display: grid;
        grid-template-columns: 75% 25%;
        grid-gap: 1cm;
    }
    aside {margin-top:0}
}

@media(min-width:1500px) {
    header h1 {
        letter-spacing: 0.4cm;
    }
}

@media(min-width:1875px) {
    header h1 {
        letter-spacing: 0.5cm;
    }
}